<template>
    <div>
        <x-header>
            <img slot="headerImage" src="../../../assets/kongyunfuwu/banner.jpg" alt="">
            <h1 slot="headerTitle">{{$store.state.navShow == true ? '空运服务—国际空运快线小包运输方案' : 'Air Freight Service - International Air Freight Express Small Package Transportation Solution'}}</h1>
        </x-header>
        <div class="airServer">
            <p>{{$store.state.navShow == true ? 'MG集团为客户提供石家庄—莫斯科航空包机服务，以最快速的货运周转方案和最低的费率，满足中小货运的物流需求。' : 'MG Group provides Shijiazhuang-Moscow air charter service for customers, and meets the logistics needs of small and medium-sized freight with the fastest freight turnover plan and the lowest rate.'}}</p>
        <!-- 航空专线 -->
            <div class="air_line">

                <div class="air_line_L">
                    <p>{{$store.state.navShow == true ? '航空专线' : 'Airline'}}</p>
                    <div class="lineDiv" data-aos="fade-right">
                        <p class="firstP">{{$store.state.navShow == true ? '(石家庄) 正定' : '(Shijiazhuang) Zhengding'}}</p>
                        <p class="zhi">{{$store.state.navShow == true ? '至':'to'}}</p>
                        <p class="thirdP">{{$store.state.navShow == true ?'莫斯科/比什凯克':'Moscow/Bishkek'}}</p>
                    </div>
                    <div class="BigTitleShow">

                        <div class="titleItems" v-for="(item,index) in $store.state.navShow == true ? BigTitleArr : BigTitleArrE " :key="index" data-aos="flip-left" :data-aos-delay="Number(index * 200)" data-aos-duration="500">
                            <img :src="item.titleImg" alt="" :style="$store.state.navShow == true ? '':'width: 38px; height: 30px;'">
                            <p>{{item.title}}</p>
                        </div>

                    </div>
                </div>
                <div class="air_line_R" >
                    <div class="air_line_R_donghua" data-aos="fade-up-left">
                        <img class="air1" src="../../../assets/kongyunfuwu/airParcel1.png" alt="" >
                        <div class="air_line_R_donghua2" data-aos="fade-left">
                            <img class="air2"  src="../../../assets/kongyunfuwu/airParcel2.png" alt="" data-aos-anchor-placement="center-center">
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div >
            <img src="../../../assets/kongyunfuwu/bottomDots.png" alt="" style="vertical-align: middle;">
        </div>
    </div>
</template>
<script>
// 头尾导入
import xHeader from '../../../components/header.vue'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader
  },
  data () {
    return {
      // 展示大标题的数组
      BigTitleArr: [
        {
          titleImg: require('../../../assets/kongyunfuwu/number1.png'),
          title: '国际航空包机物流解决方案提供商'
        },
        {
          titleImg: require('../../../assets/kongyunfuwu/number2.png'),
          title: '专业海外团队满足一站式服务需求'
        },
        {
          titleImg: require('../../../assets/kongyunfuwu/number3.png'),
          title: '专线小包业务高效配送各类型快件'
        }
      ],
      BigTitleArrE: [
        {
          titleImg: require('../../../assets/kongyunfuwu/number1.png'),
          title: 'International air charter logistics solution provider'
        },
        {
          titleImg: require('../../../assets/kongyunfuwu/number2.png'),
          title: 'Professional overseas team to meet one-stop service needs.'
        },
        {
          titleImg: require('../../../assets/kongyunfuwu/number3.png'),
          title: 'Dedicated line small package business efficiently distributes various types of express'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
    .airServer{
        width: var(--width1200px);
        margin: 117px auto 0px;
        text-align: left;
        & > p{
            font-family: Microsoft YaHei;
            color: #666666;

        }
        .air_line{
            display: flex;

            .air_line_L{
                // width: 580px;
                width: 54%;
                height: 625px;
                // background: pink;
                & > p{
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #2E67B1;
                    margin: 87px 0 18px 0;
                }
                .lineDiv{
                    width: 100%;
                    height: 46px;
                    // background: pink;
                    display: flex;
                    padding-right: 13px;
                    box-sizing: border-box;
                    & > p{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background:linear-gradient(to right, #eaf0f7,#ebf0f6) ;
                        color: #666;
                    }
                    .firstP{
                        width: 200px;
                        height: 100%;
                    }
                    .zhi{
                        width: 50px;
                        margin: 0 5px;
                    }
                    .thirdP{
                        width: calc(100% - 240px - 50px);
                        background:linear-gradient(to right, #eaf0f7,#fdfdfd) ;
                    }
                }

                // 三行标题
                .BigTitleShow{
                    padding-right: 85px;
                    .titleItems{
                        display: flex;

                        float: left;
                        & > p{
                            font-size: 22px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #2E67B1;
                            margin-left: 15px;
                        }
                        &:nth-child(2){
                           width: 100%;
                           display: flex;
                           justify-content: center;
                           margin-top: 90px;
                        }
                        &:nth-child(3){
                            float: right;
                            margin-top: 90px;
                        }
                        &:nth-child(1){
                            margin-top: 105px;
                        }
                    }

                }

            }
            .air_line_R{
                position: relative;
                height: 625px;
                width: calc(100% - 710px);
                // background: red;
                .air_line_R_donghua{
                    width: 100%;
                    height: 350px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    // background: pink;
                      & > .air1{
                        position: absolute;
                        position: absolute;
                        bottom: -25px;
                        left: -290px;
                    }
                    .air_line_R_donghua2{
                        width: 100%;
                        height: 110px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        // background: yellow;
                         & > .air2{
                            position: absolute;
                            bottom: -37px;
                            right: -205px;
                        }
                    }

                }

            }
        }
    }
</style>
